import { Button, Modal, Select, Text } from "@mantine/core";
import dayjs from "dayjs";
import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useAddSchedule } from "../Employee/services/usePostData";
import { useUpdateSchedule } from "./services/useUpdateSchedule";

const AddModal = () => {
  const { addScheduleModal, setAddScheduleModal, currentLocationId } =
    useContext(AppContext);
  const [timeStart, setTimeStart] = useState("");
  const [timeEnd, setTimeEnd] = useState("");
  const [error, setError] = useState("");
  const times = [
    { value: "12:00 AM", label: "12:00 AM" },
    { value: "12:15 AM", label: "12:15 AM" },
    { value: "12:30 AM", label: "12:30 AM" },
    { value: "12:45 AM", label: "12:45 AM" },
    { value: "1:00 AM", label: "1:00 AM" },
    { value: "1:15 AM", label: "1:15 AM" },
    { value: "1:30 AM", label: "1:30 AM" },
    { value: "1:45 AM", label: "1:45 AM" },
    { value: "2:00 AM", label: "2:00 AM" },
    { value: "2:15 AM", label: "2:15 AM" },
    { value: "2:30 AM", label: "2:30 AM" },
    { value: "2:45 AM", label: "2:45 AM" },
    { value: "3:00 AM", label: "3:00 AM" },
    { value: "3:15 AM", label: "3:15 AM" },
    { value: "3:30 AM", label: "3:30 AM" },
    { value: "3:45 AM", label: "3:45 AM" },
    { value: "4:00 AM", label: "4:00 AM" },
    { value: "4:15 AM", label: "4:15 AM" },
    { value: "4:30 AM", label: "4:30 AM" },
    { value: "4:45 AM", label: "4:45 AM" },
    { value: "5:00 AM", label: "5:00 AM" },
    { value: "5:15 AM", label: "5:15 AM" },
    { value: "5:30 AM", label: "5:30 AM" },
    { value: "5:45 AM", label: "5:45 AM" },
    { value: "6:00 AM", label: "6:00 AM" },
    { value: "6:15 AM", label: "6:15 AM" },
    { value: "6:30 AM", label: "6:30 AM" },
    { value: "6:45 AM", label: "6:45 AM" },
    { value: "7:00 AM", label: "7:00 AM" },
    { value: "7:15 AM", label: "7:15 AM" },
    { value: "7:30 AM", label: "7:30 AM" },
    { value: "7:45 AM", label: "7:45 AM" },
    { value: "8:00 AM", label: "8:00 AM" },
    { value: "8:15 AM", label: "8:15 AM" },
    { value: "8:30 AM", label: "8:30 AM" },
    { value: "8:45 AM", label: "8:45 AM" },
    { value: "9:00 AM", label: "9:00 AM" },
    { value: "9:15 AM", label: "9:15 AM" },
    { value: "9:30 AM", label: "9:30 AM" },
    { value: "9:45 AM", label: "9:45 AM" },
    { value: "10:00 AM", label: "10:00 AM" },
    { value: "10:15 AM", label: "10:15 AM" },
    { value: "10:30 AM", label: "10:30 AM" },
    { value: "10:45 AM", label: "10:45 AM" },
    { value: "11:00 AM", label: "11:00 AM" },
    { value: "11:15 AM", label: "11:15 AM" },
    { value: "11:30 AM", label: "11:30 AM" },
    { value: "11:45 AM", label: "11:45 AM" },
    { value: "12:00 PM", label: "12:00 PM" },
    { value: "12:15 PM", label: "12:15 PM" },
    { value: "12:30 PM", label: "12:30 PM" },
    { value: "12:45 PM", label: "12:45 PM" },
    { value: "1:00 PM", label: "1:00 PM" },
    { value: "1:15 PM", label: "1:15 PM" },
    { value: "1:30 PM", label: "1:30 PM" },
    { value: "1:45 PM", label: "1:45 PM" },
    { value: "2:00 PM", label: "2:00 PM" },
    { value: "2:15 PM", label: "2:15 PM" },
    { value: "2:30 PM", label: "2:30 PM" },
    { value: "2:45 PM", label: "2:45 PM" },
    { value: "3:00 PM", label: "3:00 PM" },
    { value: "3:15 PM", label: "3:15 PM" },
    { value: "3:30 PM", label: "3:30 PM" },
    { value: "3:45 PM", label: "3:45 PM" },
    { value: "4:00 PM", label: "4:00 PM" },
    { value: "4:15 PM", label: "4:15 PM" },
    { value: "4:30 PM", label: "4:30 PM" },
    { value: "4:45 PM", label: "4:45 PM" },
    { value: "5:00 PM", label: "5:00 PM" },
    { value: "5:15 PM", label: "5:15 PM" },
    { value: "5:30 PM", label: "5:30 PM" },
    { value: "5:45 PM", label: "5:45 PM" },
    { value: "6:00 PM", label: "6:00 PM" },
    { value: "6:15 PM", label: "6:15 PM" },
    { value: "6:30 PM", label: "6:30 PM" },
    { value: "6:45 PM", label: "6:45 PM" },
    { value: "7:00 PM", label: "7:00 PM" },
    { value: "7:15 PM", label: "7:15 PM" },
    { value: "7:30 PM", label: "7:30 PM" },
    { value: "7:45 PM", label: "7:45 PM" },
    { value: "8:00 PM", label: "8:00 PM" },
    { value: "8:15 PM", label: "8:15 PM" },
    { value: "8:30 PM", label: "8:30 PM" },
    { value: "8:45 PM", label: "8:45 PM" },
    { value: "9:00 PM", label: "9:00 PM" },
    { value: "9:15 PM", label: "9:15 PM" },
    { value: "9:30 PM", label: "9:30 PM" },
    { value: "9:45 PM", label: "9:45 PM" },
    { value: "10:00 PM", label: "10:00 PM" },
    { value: "10:15 PM", label: "10:15 PM" },
    { value: "10:30 PM", label: "10:30 PM" },
    { value: "10:45 PM", label: "10:45 PM" },
    { value: "11:00 PM", label: "11:00 PM" },
    { value: "11:15 PM", label: "11:15 PM" },
    { value: "11:30 PM", label: "11:30 PM" },
    { value: "11:45 PM", label: "11:45 PM" },
  ];
  const { mutate, isLoading: isAdding } = useAddSchedule();
  const { mutate: mutateEdit, isLoading: isEditing } = useUpdateSchedule();

  const handleAdd = () => {
    const values = {
      start_at: `${addScheduleModal.date} ${dayjs("1/1/1 " + timeStart).format(
        "HH:mm"
      )}`,
      end_at: `${addScheduleModal.date} ${dayjs("1/1/1 " + timeEnd).format(
        "HH:mm"
      )}`,
      employee_id: addScheduleModal.id,
      location_id: currentLocationId,
    };

    mutate(values, {
      onSuccess: () => {
        setAddScheduleModal({ opened: false, date: null, id: null });
      },
      onError: (error) => {
        console.log(error);
        setError("Error");
      },
    });
  };
  const handleEdit = () => {
    const values = {
      start_at: `${addScheduleModal.date} ${dayjs("1/1/1 " + timeStart).format(
        "HH:mm"
      )}`,
      end_at: `${addScheduleModal.date} ${dayjs("1/1/1 " + timeEnd).format(
        "HH:mm"
      )}`,
      employee_id: addScheduleModal.id,
      location_id: currentLocationId,
    };
    mutateEdit(
      { data: values, id: addScheduleModal.editId },
      {
        onSuccess: () => {
          setAddScheduleModal({ opened: false, date: null, id: null });
        },
        onError: (error) => {
          console.log(error);
          setError("Error");
        },
      }
    );
  };

  return (
    <Modal
      centered
      opened={addScheduleModal.opened}
      onClose={() => setAddScheduleModal({ opened: false, date: null })}
      title={` ${addScheduleModal.editId ? "Edit" : "Add"} Schedule for ${
        addScheduleModal.date
      }`}
    >
      <Text>{error}</Text>
      <Select
        value={timeStart}
        onChange={setTimeStart}
        data={times}
        searchable
        defaultValue={times[0].value}
      />
      <br />
      <Select value={timeEnd} searchable onChange={setTimeEnd} data={times} />
      <br />
      <Button
        variant="filled"
        color="indigo"
        onClick={addScheduleModal.editId ? handleEdit : handleAdd}
        loading={isAdding || isEditing}
      >
        {addScheduleModal.editId ? "Edit" : "Add"}
      </Button>
    </Modal>
  );
};

export default AddModal;
