import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { LOCATION_ROUTE } from "../../../utils/ApiRoutes";
import { PostQuery } from "../../../utils/QueryCalls";


const addLocation = async (data) => {
    console.log(data, 'loc')
    return await PostQuery(LOCATION_ROUTE, data)
}

export const useAddLocation = () => {
    const queryClient = useQueryClient()

    return useMutation(addLocation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['locations'])
            showNotification({
                title: "Location added"
            })
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}