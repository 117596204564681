import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`  
     
* {
    margin: 0;
  padding: 0;
  box-sizing: border-box;
}
`;
export default GlobalStyles;
