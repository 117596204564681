import { Avatar, Button, createStyles, Grid, Text } from "@mantine/core";
import React from "react";
import Dashboard from "../components/Dashboard/Dashboard";
import AddBox from "../components/Roaster/AddBox";

const useStyles = createStyles((theme, _params, getRef) => ({
  gridMain: {
    border: "1px solid lightgray",
  },
  unselectedCol: {
    borderLeft: "1px solid lightgray",
  },
  gridDate: {
    fontSize: "34px",
    textAlign: "center",
  },
  gridDay: {
    fontSize: "20px",
    textAlign: "center",
  },
  workSheet: {
    fontWeight: "500",
    alignSelf: "center",
  },
  selectedCol: {
    borderLeft: "1px solid lightgray",
    backgroundColor: "aqua",
    border: "3px solid tomato",
    display: "flex",
    justifyContent: "center",
  },
  empDetailCol: {
    display: "flex",
    gap: "0rem",
    alignItems: "center",
  },
}));
const Testpage = () => {
  const { classes } = useStyles();

  return (
    <Dashboard>
      <Grid grow className={classes.gridMain} columns={16}>
        <Grid.Col className={classes.empDetailCol} span={2}>
          <Button fullWidth color={"red"}>
            Select All
          </Button>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Saturday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Sunday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Monday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Tuesday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Wednesday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Thursday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
        <Grid.Col className={classes.unselectedCol} span={2}>
          <p className={classes.gridDay}>Friday</p>
          <p className={classes.gridDate}>25</p>
        </Grid.Col>
      </Grid>

      <Grid
        grow
        className={classes.gridMain}
        columns={16}
        style={{ marginTop: "7px" }}
      >
        <Grid.Col span={2} className={classes.empDetailCol}>
          <Avatar color="cyan" radius="xl">
            MK
          </Avatar>
          <div>
            <Text size="lg" weight={600}>
              Employee Name
            </Text>
            <Text size="sm" color="gray" weight={600}>
              14.00 Hrs/$343
            </Text>
          </div>
        </Grid.Col>
        <Grid.Col span={2} className={classes.selectedCol}>
          <p className={classes.workSheet}>12Am-12PM</p>
        </Grid.Col>
        <Grid.Col span={2} className={classes.unselectedCol}></Grid.Col>
        <Grid.Col span={2} className={classes.unselectedCol}>
          <AddBox />
        </Grid.Col>
        <Grid.Col span={2} className={classes.unselectedCol}></Grid.Col>
        <Grid.Col span={2} className={classes.selectedCol}>
          <p className={classes.workSheet}> 7PM-11PM</p>
        </Grid.Col>
        <Grid.Col span={2} className={classes.unselectedCol}></Grid.Col>
        <Grid.Col span={2} className={classes.selectedCol}>
          <p className={classes.workSheet}> 2AM-8PM</p>
        </Grid.Col>
      </Grid>
    </Dashboard>
  );
};

export default Testpage;
