import React, { useState } from "react";
import {
  Table,
  createStyles,
  Grid,
  Text,
  Select,
  Stack,
  ActionIcon,
  Group,
  Loader,
  Center,
} from "@mantine/core";
import { useQuery } from "react-query";
import { FetchQuery } from "../../utils/QueryCalls";
import { SCHEDULE_ROUTE, TIMESHEET_ROUTE } from "../../utils/ApiRoutes";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import dayjs from "dayjs";
import { ArrowLeft, ArrowLeftBar, ArrowRight } from "tabler-icons-react";

const useStyles = createStyles((theme, _params, getRef) => ({
  tbody: {
    fontWeight: "100",
  },
}));
const PreviousRecord = ({ currentEmployee }) => {
  const { classes } = useStyles();
  const { currentDate, currentMode } = useContext(AppContext);
  const { isLoading, data, refetch, isFetching } = useQuery(
    ["daily"],
    () =>
      FetchQuery(
        `${TIMESHEET_ROUTE}/${currentMode.current}?emp_id=${currentEmployee}&date=${currentDate.current}`
      ),
    {
      onSuccess: (data) => {
        console.log(data);
      },
    }
  );
  const handleChangeMonth = (type) => {
    if (type === "left") {
      const newDate = dayjs(currentDate.current)
        .subtract(1, currentMode.current === "daily" ? "M" : "y")
        .format("YYYY-MM-DD");
      currentDate.current = newDate;
    } else {
      const newDate = dayjs(currentDate.current)
        .add(1, currentMode.current === "daily" ? "M" : "y")
        .format("YYYY-MM-DD");
      currentDate.current = newDate;
    }
    refetch();
  };

  const handleModeChange = (value) => {
    currentMode.current = value;
    refetch();
  };
  return (
    <>
      <Grid justify="space-around">
        <Grid.Col span={7}>
          <Text size="lg" weight={700}>
            Previous Record
          </Text>
        </Grid.Col>
        <Grid.Col span={3}>
          <Select
            placeholder="Daily"
            value={currentMode.current}
            data={[
              { value: "daily", label: "Daily" },
              { value: "monthly", label: "Monthly" },
              // { value: "yearly", label: "Yearly" },
            ]}
            onChange={handleModeChange}
          />
        </Grid.Col>
      </Grid>
      <Group position="apart" px={10} my={5}>
        <ActionIcon onClick={() => handleChangeMonth("left")}>
          <ArrowLeft />
        </ActionIcon>
        <p>
          {dayjs(currentDate.current).format(
            currentMode.current === "daily" ? "MMMM, YYYY" : "YYYY"
          )}
        </p>
        <ActionIcon onClick={() => handleChangeMonth("right")}>
          <ArrowRight />
        </ActionIcon>
      </Group>
      <Table horizontalSpacing="md" verticalSpacing="xs">
        <thead>
          <tr>
            <th>From </th>
            <th>To </th>
            <th>HRS </th>
          </tr>
        </thead>
        <tbody style={{ width: "100%" }}>
          {isFetching ? (
            <Loader />
          ) : (
            <>
              {data?.map((e) => (
                <tr className={classes.tbody}>
                  <td>
                    <Text weight={"normal"} color=" ">
                      {dayjs(e?.date)
                        .startOf(currentMode.current[0].toUpperCase())
                        .format("MMMM DD,YYYY")}
                    </Text>
                  </td>
                  <td>
                    <Text weight={"normal"} color="gray">
                      {dayjs(e?.date)
                        .endOf(currentMode.current[0].toUpperCase())
                        .format("MMMM DD,YYYY")}
                    </Text>
                  </td>
                  <td>
                    <Text weight={"normal"} color="gray">
                      {e?.totalHrs}
                    </Text>
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default PreviousRecord;
