import {
  Button,
  createStyles,
  Grid,
  Space,
  Table,
  Text,
  TextInput,
} from "@mantine/core";
import React, { useRef, useState } from "react";
import { useQuery } from "react-query";
import { Ladder, Menu2, Photo, Search } from "tabler-icons-react";
import Dashboard from "../components/Dashboard/Dashboard";
import AddModal from "../components/Location/AddModal";
import LocationItem from "../components/Location/LocationItem";
import { LOCATION_ROUTE } from "../utils/ApiRoutes";
import { FetchQuery } from "../utils/QueryCalls";

const useStyles = createStyles((theme, _params, getRef) => ({
  toprow: {
    display: "flex",
    justifyContent: "space-between",
  },
  maincontainer: {
    width: "80%",
    margin: "auto",
  },
  addlocation: {
    backgroundColor: "#57419D",
    color: "white",
    ":hover": {
      backgroundColor: "#57408D",
    },
  },
  buttonsection: {
    display: "flex",
    alignItems: "center",
  },
}));

const LocationPage = () => {
  const { classes } = useStyles();
  const [opened, setOpened] = useState(false);
  const selectedIndex = useRef(null);
  const [searchVal, setSearchVal] = useState("");

  const { data, isLoading } = useQuery(["locations"], () =>
    FetchQuery(LOCATION_ROUTE)
  );

  return (
    <Dashboard>
      {!isLoading && (
        <AddModal
          opened={opened}
          setOpened={setOpened}
          selectedIndex={selectedIndex}
        />
      )}
      <div className={classes.maincontainer}>
        <Grid>
          <Grid.Col span={10}>
            <Text color="black" size="xl"></Text>
          </Grid.Col>
          <Grid.Col span={2}>
            <Button
              className={classes.addlocation}
              onClick={() => setOpened(true)}
            >
              Add Location{" "}
            </Button>
          </Grid.Col>
        </Grid>
        <Space h="xl" />

        <TextInput
          variant="filled"
          placeholder="Search Locations"
          icon={<Search size={23} />}
          radius="xl"
          onChange={(e) => setSearchVal(e.target.value)}
        />

        <Space h="lg" />

        <Table horizontalSpacing="lg" verticalSpacing="md" fontSize="md">
          <thead>
            <tr>
              <th>Location Name</th>
              <th>Location Address</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data
              ?.filter((e) =>
                e?.name?.toLowerCase().includes(searchVal.toLowerCase())
              )
              .map((location) => (
                <LocationItem
                  key={location.id}
                  location={location}
                  setOpened={setOpened}
                  selectedIndex={selectedIndex}
                />
              ))}
          </tbody>
        </Table>
      </div>
    </Dashboard>
  );
};

export default LocationPage;
