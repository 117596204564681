import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { MESSAGE_ROUTE } from "../../../utils/ApiRoutes";
import { PostQuery } from "../../../utils/QueryCalls";


const SendMessage = async ({ message, id }) => {
    return await PostQuery(MESSAGE_ROUTE + "/admin/" + id, message)
}

export const useSendMessage = () => {
    const queryClient = useQueryClient()

    return useMutation(SendMessage, {
        onSuccess: () => {
            queryClient.invalidateQueries(['Messages'])
            showNotification({
                title: "Message sent",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not send message",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}