import { useMutation, useQueryClient } from "react-query";
import axios from 'axios'
import { EMPLOYEE_ROUTE, SCHEDULE_COPY_ROUTE, SCHEDULE_COPY_SPECIFIC_ROUTE, SCHEDULE_ROUTE } from "../../../utils/ApiRoutes";
import { showNotification } from "@mantine/notifications";
import { PostQuery } from "../../../utils/QueryCalls";

const addEmployee = async (data) => {
    return await axios.post(EMPLOYEE_ROUTE, data, {
        withCredentials: true
    })
}
export const useAddEmployee = () => {
    const queryClient = useQueryClient()

    return useMutation(addEmployee, {
        onSuccess: () => {
            queryClient.invalidateQueries(['activeEmployees']);
            queryClient.invalidateQueries(['employees']);
            showNotification({
                title: "Employee added",
                color: 'green'
            })
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}

const addSchedule = async (data) => {
    return await PostQuery(SCHEDULE_ROUTE, data);
}
export const useAddSchedule = () => {
    const queryClient = useQueryClient()

    return useMutation(addSchedule, {
        onSuccess: () => {
            showNotification({
                title: "Schedule added",
                color: 'green'
            })
            queryClient.invalidateQueries(['schedules'])
            queryClient.invalidateQueries(['employee_roster'])
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}

const copyScheduleNextWeek = async (data) => {
    console.log(data, 'from axios')
    return await axios.post(SCHEDULE_COPY_ROUTE, data, {
        withCredentials: true
    })
}
export const useCopyScheduleNextWeek = () => {
    const queryClient = useQueryClient()

    return useMutation(copyScheduleNextWeek, {
        onSuccess: (data) => {
            if (typeof (data.data) == "object" && data.data.length === 0) {
                showNotification({
                    title: "Nothing to copy",
                    color: 'orange'
                })
                return;
            }
            showNotification({
                title: "Schedule copied",
                color: 'green'
            })
            queryClient.invalidateQueries(['schedules'])
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}
const copyScheduleSpecificWeek = async (data) => {
    return await axios.post(SCHEDULE_COPY_SPECIFIC_ROUTE, data, {
        withCredentials: true
    })
}
export const useCopyScheduleSpecificWeek = () => {
    const queryClient = useQueryClient()

    return useMutation(copyScheduleSpecificWeek, {
        onSuccess: (data) => {
            if (typeof (data.data) == "object" && data.data.length === 0) {
                showNotification({
                    title: "Nothing to copy",
                    color: 'orange'
                })
                return;
            }
            showNotification({
                title: "Schedule copied",
                color: 'green'
            })
            queryClient.invalidateQueries(['schedules'])
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}