import { ColorSchemeProvider, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoute from "./AppRoute";
import { AppProvider } from "./context/AppContext";
import GlobalStyles from "./styles/Global";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();

function App() {
  const [colorScheme, setColorScheme] = useState("light");
  const toggleColorScheme = (value) => {
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"));
  };
  return (
    <QueryClientProvider client={queryClient}>
      <AppProvider>
        <BrowserRouter>
          <ColorSchemeProvider
            colorScheme={colorScheme}
            toggleColorScheme={toggleColorScheme}
          >
            <MantineProvider
              theme={{
                fontFamily: "Inter, sans-serif",
              }}
            >
              <NotificationsProvider
                position="top-right"
                zIndex={9999}
                autoClose={3000}
              >
                <GlobalStyles />
                <AppRoute />
              </NotificationsProvider>
            </MantineProvider>
          </ColorSchemeProvider>
        </BrowserRouter>
      </AppProvider>
    </QueryClientProvider>
  );
}
export default App;
