import React from "react";
import {
  Button,
  Card,
  Grid,
  Group,
  ScrollArea,
  Table,
  Text,
} from "@mantine/core";
import { useQuery } from "react-query";
import { FetchQuery } from "../../utils/QueryCalls";
import { WORDPRESS_ROUTE } from "../../utils/ApiRoutes";

const WordpressForms = () => {
  const { data } = useQuery(["wordpress"], () => FetchQuery(WORDPRESS_ROUTE));
  const rows = data?.map((form) => (
    <tr key={form.id}>
      <td>{form?.name}</td>
      <td>{form?.email}</td>
      <td>{form?.phone}</td>
      <td>{form?.address}</td>
      <td>{form?.suburb}</td>
      <td>{form?.position}</td>
      <td>{form?.postCode}</td>
      <td>
        <Button
          component="a"
          target="_blank"
          href={form?.file}
          size="xs"
          radius="lg"
        >
          View
        </Button>
      </td>
    </tr>
  ));
  return (
    <Grid.Col span={4}>
      <Card
        shadow="xs"
        p="lg"
        radius="lg"
        withBorder
        style={{ height: "100%" }}
      >
        <Card.Section withBorder inheritPadding p="md">
          <Group position="apart">
            <Text weight={500}>Application Forms</Text>
          </Group>
        </Card.Section>
        <Table fontSize="xs">
          <ScrollArea style={{ height: 300 }}>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Address</th>
                <th>Suburb</th>
                <th>Position</th>
                <th>PostCode</th>
                <th>File</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </ScrollArea>
        </Table>
      </Card>
    </Grid.Col>
  );
};

export default WordpressForms;
