import React from "react";
import { Button, Group, RingProgress, Text } from "@mantine/core";
import dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as duration from "dayjs/plugin/duration";
import * as isToday from "dayjs/plugin/isToday";
import * as relativeTime from "dayjs/plugin/relativeTime";

const RingTime = ({ name, data }) => {
  dayjs.extend(utc);
  dayjs.extend(duration);
  dayjs.extend(isToday);
  dayjs.extend(relativeTime);

  const diffHour = `${dayjs(data?.end_at).utc().diff(data.start_at, "m")}`;
  const diff = dayjs(data?.end_at).utc().diff(data.start_at, "minutes",true);

  const calculateTimes = (timeInMin)=>{
      return dayjs.duration(timeInMin,'minutes').format("HH:MM:ss")
  }
  const calculateWorkTime = (clock) => {
    let totalTime = 0;
    clock?.forEach((curr) => {
      if (curr?.checked_out_at) {
        totalTime += dayjs(curr.checked_out_at)
          .utc()
          .diff(curr?.checked_in_at, "minutes",true);
      } else {
        if (dayjs(curr?.checked_in_at).utc().isToday()) {
          totalTime += dayjs().utc(true).diff(curr?.checked_in_at, "minutes",true);
        }
      }
    });
    return totalTime;
  };
  let workTime = calculateWorkTime(data?.clock);
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Text size="lg" weight={600}>
          {name}
        </Text>
        <Button style={{ color: "green", backgroundColor: "#abedcc" }}>
          {data?.location?.name}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "0.5em",
        }}
      >
        <div>
          <Text weight={700}>Date</Text>
          <Text color="#8B9390">
            {dayjs(data.start_at).utc().format("DD MMM,YYYY")}
          </Text>
        </div>
        <Text color="#23B480" size="lg" weight={"bolder"}>
          Present
        </Text>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          {data.clock.map((clock) => (
            <Group position="apart" key={clock?.id}>
              <div>
                <Text weight={700}>In Time</Text>
                <Text color="#8B9390">
                  {clock?.checked_in_at
                    ? dayjs(clock?.checked_in_at).utc(false).format("hh:mm:ss a")
                    : "NA"}
                </Text>
              </div>
              <div>
                <Text weight={700}>Out Time</Text>
                <Text color="#8B9390">
                  {clock?.checked_out_at
                    ? dayjs(clock?.checked_out_at).utc(false).format("hh:mm:ss a")
                    : "NA"}
                </Text>
              </div>
            </Group>
          ))}
        </div>
        <RingProgress
          size={180}
          thickness={8}
          sections={[
            { value: workTime ? (workTime / diff) * 100 : 0, color: "#23B480" },
          ]}
          label={
            <>
              <Text color="black" weight={700} align="center" size="md">
                {calculateTimes(workTime)}
              </Text>
              <Text color="#8B9390" weight={800} align="center" size="sm">
                of {calculateTimes(diff)}
              </Text>
            </>
          }
        />
      </div>
    </>
  );
};

export default RingTime;
