import axios from 'axios'
import { USER_ROUTE } from './ApiRoutes'
import { LOGINPAGE } from './Constants';

export const FetchData = async (route) => {
    try {
        const { data } = await axios.get(route, {
            withCredentials: true,
        });
        return data;
    } catch (e) {
        console.log(e, "Api error")
        if (e.response.status === 401 && window.location.pathname !== LOGINPAGE) {
            window.location.pathname = LOGINPAGE
        }
        if (e.response) { throw e?.response?.data?.message } else if (e.name === "AxiosError") {
            throw "Failed to connect to the server";
        }
        throw e.message
    }
}

export const PostData = async (route, body, method = 'post', multipart = false) => {
    try {

        const data = await axios({
            method,
            url: route,
            data: !multipart ? JSON.stringify(body) : body,
            headers: {
                'Accept': 'application/json',
                'Content-Type': !multipart ? 'application/json' : undefined,
            },
            withCredentials: true
        })
        return data.data
    } catch (e) {
        console.log(e, "Api Request error")
        if (e.response.status === 401 && window.location.pathname !== LOGINPAGE) {
            window.location.pathname = LOGINPAGE
            document.cookie = "";

        }
        if (e.response) { throw e?.response?.data?.message }
        else if (e.name === "AxiosError") {
            throw "Failed to connect to the server";
        }
        throw e.message
    }
}
export const DeleteData = async (route) => {
    try {
        const data = await axios.delete(route, {
            withCredentials: true
        });
        return data.data
    } catch (e) {
        console.log(e, "Api Request error")
        if (e.response.status === 401 && window.location.pathname !== LOGINPAGE) {
            window.location.pathname = LOGINPAGE
            document.cookie = "";

        }
        if (e.response) { throw e.response.data.message }
        throw e.message
    }
}

export const AutoLoginUser = async () => {
    try {
        const data = await axios.get(USER_ROUTE, {
            withCredentials: true,
        });
        return data.data;
    } catch (e) {
        console.log(e, "auto error")
        if (e.response.status === 401 && window.location.pathname !== LOGINPAGE) {
            window.location.pathname = LOGINPAGE;
            document.cookie = "";
        }
        if (e.response) { throw e.response.data.message }
        throw e.message
    }
}