import { useMutation, useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { DeleteQuery, UpdateQuery } from "../../../utils/QueryCalls";
import { SCHEDULE_ROUTE } from "../../../utils/ApiRoutes";


const UpdateSchedule = async ({ id, data }) => {
    return await UpdateQuery(SCHEDULE_ROUTE + "/" + id, data)
}

export const useUpdateSchedule = () => {
    const queryClient = useQueryClient()

    return useMutation(UpdateSchedule, {
        onSuccess: () => {
            queryClient.invalidateQueries(['schedules'])
            queryClient.invalidateQueries(['employee_roster'])
            showNotification({
                title: "Schedule updated",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not edit Schedule",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}