import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { SCHEDULE_ROUTE } from "../../../utils/ApiRoutes";
import { UpdateQuery } from "../../../utils/QueryCalls";


const ApproveEveryoneTimesheet = async ({ query }) => {
    return await UpdateQuery(SCHEDULE_ROUTE + "/approveeveryone" + query)
}

export const useApproveEveryoneTimesheet = () => {
    const queryClient = useQueryClient()

    return useMutation(ApproveEveryoneTimesheet, {
        onSuccess: () => {
            queryClient.invalidateQueries(['Timesheets'])
            showNotification({
                title: "Approved",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not approve schedule",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}