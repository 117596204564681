import dayjs from "dayjs";
import { useRef } from "react";
import { createContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [addScheduleModal, setAddScheduleModal] = useState({
    opened: false,
    date: null,
    id: null,
  });
  const [employeeRosterModal, setEmployeeRosterModal] = useState({
    opened: false,
    id: null,
  });
  const [currentLocationId, setCurrentLocationId] = useState(null);
  const [locations, setLocations] = useState([]);
  const [currentSchedule, setCurrentSchedule] = useState(null);
  const currentMode = useRef("daily");
  const currentDate = useRef(dayjs().format("YYYY-MM-DD"));

  const values = {
    user,
    setUser,
    setAddScheduleModal,
    addScheduleModal,
    setCurrentLocationId,
    currentLocationId,
    setCurrentSchedule,
    currentSchedule,
    setEmployeeRosterModal,
    employeeRosterModal,
    locations,
    setLocations,
    currentMode,
    currentDate,
  };

  return <AppContext.Provider value={values}>{children}</AppContext.Provider>;
};
