import { Button, createStyles, Menu } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import React from "react";
import { useQueryClient } from "react-query";
import { Map2, Trash } from "tabler-icons-react";
import { useDeleteLocation } from "./services/useDeleteLocation";

const useStyles = createStyles((theme, _params, getRef) => ({
  buttonsection: {
    display: "flex",
    alignItems: "center",
  },
}));

const LocationItem = ({ location, setOpened, selectedIndex }) => {
  const { classes } = useStyles();
  const [menuopened, handlers] = useDisclosure(false);
  const { mutate } = useDeleteLocation();

  const handleDelete = async () => {
    mutate(location.id, {
      onSuccess: () => {
        setOpened(false);
        selectedIndex.current = null;
      },
    });
  };
  return (
    <tr>
      <td>{location.name}</td>
      <td>
        <Map2 /> {location.location}
      </td>
      <td className={classes.buttonsection}>
        <Button
          variant="light"
          color="indigo"
          onClick={() => {
            setOpened(true);
            selectedIndex.current = location.id;
          }}
        >
          Edit Setting
        </Button>
        <Menu
          size="sm"
          position="top"
          gutter={16}
          withArrow
          opened={menuopened}
          onOpen={handlers.open}
          onClose={handlers.close}
        >
          <Menu.Item
            icon={<Trash size={14} />}
            color="red"
            onClick={handleDelete}
          >
            Delete
          </Menu.Item>
        </Menu>
      </td>
    </tr>
  );
};

export default LocationItem;
