import { useMutation, useQueryClient } from "react-query";
import { showNotification } from "@mantine/notifications";
import { DeleteQuery, DeleteWithBodyQuery, PostQuery, UpdateQuery } from "../../../utils/QueryCalls";
import { EMPLOYEE_ROUTE, SCHEDULE_ROUTE } from "../../../utils/ApiRoutes";


const AddToTeam = async ({ data }) => {
    return await PostQuery(EMPLOYEE_ROUTE + "/location", data)
}

export const useAddToTeam = () => {
    const queryClient = useQueryClient()

    return useMutation(AddToTeam, {
        onSuccess: () => {
            queryClient.invalidateQueries();
            showNotification({
                title: "Employee added",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not add",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}

const RemoveFromTeam = async ({ data }) => {
    return await DeleteWithBodyQuery(EMPLOYEE_ROUTE + "/location", data)
}

export const useRemoveFromTeam = () => {
    const queryClient = useQueryClient()

    return useMutation(RemoveFromTeam, {
        onSuccess: () => {
            queryClient.invalidateQueries();
            showNotification({
                title: "Employee removed",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not remove",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}