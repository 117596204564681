import { showNotification } from "@mantine/notifications";
import axios from 'axios';
import { useMutation, useQueryClient } from "react-query";
import { EMPLOYEE_ROUTE } from "../../../utils/ApiRoutes";

const changeRole = async (data) => {
    return await axios.post(EMPLOYEE_ROUTE + "/role", data, {
        withCredentials: true
    })
}
export const useChangeRole = () => {
    const queryClient = useQueryClient()

    return useMutation(changeRole, {
        onSuccess: () => {
            queryClient.invalidateQueries(['activeEmployees']);
            queryClient.invalidateQueries(['employees']);
            showNotification({
                title: "Employee role changed",
                color: 'green'
            })
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}
