import {
  Badge,
  Button,
  Card,
  Grid,
  Group,
  Loader,
  ScrollArea,
  Text,
  TextInput,
} from "@mantine/core";
import React from "react";
import { useState } from "react";
import { useQuery } from "react-query";
import { NOTIFICATION_ROUTE } from "../../utils/ApiRoutes";
import { FetchQuery } from "../../utils/QueryCalls";
import { useSendNotification } from "./services/useSendNotification";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
import * as relativeTime from "dayjs/plugin/relativeTime";
const NotificationContainer = () => {
    dayjs.extend(utc);
    dayjs.extend(relativeTime);
  const [message, setMessage] = useState("");
  const { mutate, isLoading: sendingNotification } = useSendNotification();
  const { isLoading, data } = useQuery(
    ["notifications"],
    () => FetchQuery(NOTIFICATION_ROUTE)
  );
  const handleSend = () => {
    mutate(
      { message },
      {
        onSuccess: () => setMessage(""),
      }
    );
  };

  return (
    <Grid.Col span={3}>
      <Text size="lg" weight={"bold"}>
        Notifications
      </Text>
      <Group>
        <TextInput
          placeholder="Send notification to all users"
          radius={"lg"}
          style={{ width: "85%" }}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        />
        <Button
          radius={"lg"}
          onClick={handleSend}
          loading={sendingNotification}
        >
          Send
        </Button>
      </Group>
      <ScrollArea style={{ height: 800 }} py={10}>
        {!isLoading ? (
          <>
            {data?.notifications?.map((notification) => (
              <Card
                shadow="xs"
                p="md"
                radius="lg"
                mb={"xs"}
                withBorder
                key={notification.id}
              >
                <Text weight={500}>{notification?.description}</Text>
                <Text weight={500}  color="dimmed" size={"xs"}>{dayjs(notification?.createdAt).fromNow()}</Text>
                <Badge color="blue" variant="light">
                  {notification?.type}
                </Badge>
              </Card>
            ))}
          </>
        ) : (
          <Loader />
        )}
      </ScrollArea>
    </Grid.Col>
  );
};

export default NotificationContainer;
