/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "@mantine/core";
import React, { useContext } from "react";
import { useQuery, useQueryClient } from "react-query";
import { MapPin } from "tabler-icons-react";
import { AppContext } from "../../context/AppContext";
import { LOCATION_ROUTE } from "../../utils/ApiRoutes";
import { FetchQuery } from "../../utils/QueryCalls";

const SiteLists = () => {
  const { setCurrentLocationId, setLocations, locations, currentLocationId } =
    useContext(AppContext);
  const queryClient = useQueryClient();
  const { isLoading, data, isRefetching } = useQuery(
    ["locations"],
    () => FetchQuery(LOCATION_ROUTE),
    {
      refetchOnMount: true,
      onSuccess: (data) => {
        console.log(data, "first time check");
        if (data.length > 0 && !currentLocationId) {
          setCurrentLocationId(data[0]?.id);
          const locations = data?.map((location) => {
            return { value: location.id, label: location.name };
          });
          setLocations(locations);
        }
      },
    }
  );

  const handleChange = (val) => {
    setCurrentLocationId(val);
    queryClient.invalidateQueries(["employee_in_site"]);
  };
  return (
    <>
      {(!isLoading || !isRefetching) && (
        <Select
          placeholder={"Sites"}
          data={locations}
          radius="xl"
          icon={<MapPin size={22} color="#000" />}
          variant="filled"
          searchable
          defaultValue={1}
          onChange={handleChange}
        />
      )}
    </>
  );
};

export default SiteLists;
