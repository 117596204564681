import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { LOCATION_ROUTE } from "../../../utils/ApiRoutes";
import { UpdateQuery } from "../../../utils/QueryCalls";


const updateLocation = async ({ data, id }) => {
    return await UpdateQuery(LOCATION_ROUTE + "/" + id, data)
}

export const useUpdateLocation = () => {
    const queryClient = useQueryClient()

    return useMutation(updateLocation, {
        onSuccess: () => {
            queryClient.invalidateQueries(['locations'])
            showNotification({
                title: "Location edited",
                color: 'green'
            })
        },
        onError: (error) => {
            if (typeof (error.response.data.message) === "object") {
                error.response.data.message.map(e => {
                    return showNotification({
                        title: e,
                        color: "red"
                    })
                })
            } else {
                showNotification({
                    title: error.response.data.message,
                    color: "red"
                })
            }
        }
    });
}