import React from "react";
import { BarChart, XAxis, YAxis, Bar } from "recharts";
import { Text } from "@mantine/core";

const BarGraph = ({ data }) => {
  return (
    <>
      <Text size="lg" weight={500}>
        This week{" "}
      </Text>
      <BarChart width={400} height={300} data={data}>
        <XAxis dataKey="day"> </XAxis>
        <YAxis dataKey="hour"> </YAxis>
        <Bar dataKey="hour" fill="#23B480" barSize={20} />
      </BarChart>
    </>
  );
};

export default BarGraph;
