import { Avatar, Button, Group, Paper, Text, Badge } from "@mantine/core";
import React from "react";
import { useLeaveRequest } from "./services/LeaveRequest";

const LeaveRequestBox = ({ message, status, left = true, msgId }) => {
  const { mutate, isLoading: approving } = useLeaveRequest();

  const handleAccept = () => {
    mutate({ id: msgId, accept: true });
  };
  const handleReject = () => {
    mutate({ id: msgId, accept: false });
  };
  return (
    <div
      style={{
        display: "flex",
        alignSelf: left ? "flex-start" : "flex-end",
        gap: "0.5rem",
        marginLeft: "1rem",
        marginBottom: "3px",
      }}
    >
      {left && <Avatar src="" alt="it's me" radius="xl" color="cyan" />}
      <Paper
        shadow="sm"
        radius="xl"
        p="sm"
        withBorder
        style={{ maxWidth: "320px" }}
      >
        <Text>{message}</Text>
        {status === "PENDING" ? (
          <Group mt="xs">
            <Button
              color={"green"}
              onClick={handleAccept}
              loading={approving}
              radius="lg"
            >
              Accept
            </Button>

            <Button
              radius="lg"
              color={"red"}
              onClick={handleReject}
              loading={approving}
            >
              Reject
            </Button>
          </Group>
        ) : (
          <Badge color={status === "APPROVED" ? "green" : "red"}>
            {status}
          </Badge>
        )}
      </Paper>
    </div>
  );
};

export default LeaveRequestBox;
