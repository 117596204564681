import { Button, createStyles, Grid } from "@mantine/core";
import React, { useContext } from "react";
import { AppContext } from "../../context/AppContext";
// import hero from './hero.png';

const useStyles = createStyles((theme, _params, getRef) => ({
  p: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  span: {
    fontSize: "10px",
  },
  unselectedCol: {
    borderLeft: "1px solid lightgray",
    borderBottom: "1px solid lightgray",
  },
}));

const AddBox = ({ date, id, border = true }) => {
  const { classes } = useStyles();
  const { setAddScheduleModal } = useContext(AppContext);

  return (
    <Grid.Col span={2} className={border && classes.unselectedCol}>
      <Button
        color="indigo"
        radius={"lg"}
        onClick={() => {
          setAddScheduleModal({ opened: true, date, id });
          console.log("click");
        }}
        fullWidth
      >
        Add
      </Button>
    </Grid.Col>
  );
};

export default AddBox;
