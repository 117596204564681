import { Avatar, Paper, Text } from "@mantine/core";
import React from "react";

const MessageBox = ({ message, left = true }) => {
  return (
    <div
      style={{
        display: "flex",
        alignSelf: left ? "flex-start" : "flex-end",
        gap: "0.5rem",
        marginLeft: "1rem",
        marginBottom: "3px",
      }}
    >
      {left && <Avatar src="" alt="it's me" radius="xl" color="cyan" />}
      <Paper
        shadow="sm"
        radius="xl"
        p="sm"
        withBorder
        style={{ maxWidth: "320px" }}
      >
        <Text>{message}</Text>
      </Paper>
    </div>
  );
};

export default MessageBox;
