import { Button, createStyles, Grid, Modal, Switch } from "@mantine/core";
import dayjs from "dayjs";
import React, { useState } from "react";
import { Plus } from "tabler-icons-react";
import { EMPLOYEE_ROUTE, LOCATION_ROUTE } from "../../utils/ApiRoutes";

import { useContext } from "react";
import { useQuery } from "react-query";
import { AppContext } from "../../context/AppContext";
import { FetchQuery } from "../../utils/QueryCalls";
import { useAddToTeam, useRemoveFromTeam } from "./services/useTeamManagement";

// import TimelineComp from '../components/Roaster/TimelineComp';

const useStyles = createStyles((theme, _params, getRef) => ({
  rightheader: {
    display: "flex",
    justifyContent: "space-between",
    width: "90%",
    backgroundColor: "#001D6E",
    color: "white",
    padding: "10px 20px",
    borderBottomLeftRadius: "20px",
    borderTopLeftRadius: "20px",
    marginBottom: "1.5em",
  },
  gridMain: {
    border: "1px solid lightgray",
    width: "100%",
  },
  unselectedCol: {
    borderLeft: "1px solid lightgray",
  },
  gridDay: {
    fontSize: "34px",
    textAlign: "center",
  },
  gridDate: {
    fontSize: "20px",
    textAlign: "center",
  },
}));

const RightHeader = (props) => {
  const [opened, setOpened] = useState(false);
  const { classes } = useStyles();
  const { currentLocationId } = useContext(AppContext);
  const { data: activeEmployees } = useQuery(["activeEmployees"], () =>
    FetchQuery(EMPLOYEE_ROUTE)
  );
  const { mutate: AddToTeam } = useAddToTeam();
  const { mutate: RemoveFromTeam } = useRemoveFromTeam();
  const { data: employeesInSite } = useQuery(
    ["employee_in_site", currentLocationId],
    () => FetchQuery(`${LOCATION_ROUTE}/${currentLocationId}/team`),
    {
      enabled: !!currentLocationId,
    }
  );

  const handleTeam = (e, empId) => {
    const checked = e.currentTarget.checked;
    if (checked) {
      AddToTeam({ data: { locationId: currentLocationId, employeeId: empId } });
    } else {
      RemoveFromTeam({
        data: { locationId: currentLocationId, employeeId: empId },
      });
    }
  };

  return (
    <Grid
      grow
      className={classes.gridMain}
      columns={17}
      style={{ width: "100%" }}
    >
      <Grid.Col className={classes.empDetailCol} span={2}>
        <Button
          variant="gradient"
          gradient={{ from: "pink", to: "red" }}
          radius="xl"
          leftIcon={<Plus size={18} />}
          onClick={() => setOpened((o) => !o)}
        >
          Add to Team
        </Button>
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          title="Team Member"
        >
          {activeEmployees &&
            employeesInSite &&
            activeEmployees.map((ele) => (
              <div key={ele.id}>
                <Switch
                  label={ele.employee.name}
                  checked={
                    employeesInSite.EmployeeOnLocation.findIndex(
                      (elem) => elem.employeeId === ele.employee.id
                    ) !== -1
                  }
                  onChange={(e) => handleTeam(e, ele.employee.id)}
                />
              </div>
            ))}
        </Modal>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {" "}
          {dayjs(props.date.start).format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(1, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(1, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(2, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(2, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(3, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(3, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(4, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(4, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(5, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(5, "d").format("DD")}
        </p>
      </Grid.Col>
      <Grid.Col className={classes.unselectedCol} span={2}>
        <p className={classes.gridDate}>
          {dayjs(props.date.start).add(6, "d").format("MMM")}
        </p>
        <p className={classes.gridDay}>
          {dayjs(props.date.start).add(6, "d").format("DD")}
        </p>
        <div></div>
      </Grid.Col>
    </Grid>
  );
};

export default RightHeader;
