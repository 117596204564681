import React from "react";
import { Grid, Avatar, createStyles, Text, Button } from "@mantine/core";
import { AppContext } from "../../context/AppContext";
import { useContext } from "react";

const useStyles = createStyles((theme, _params, getRef) => ({
  avatarbox: {
    backgroundColor: "#F4F5F5",
    color: "black",
    borderRadius: "10px",
    marginBottom: "10px",
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginLeft: "8px",
    ":hover": {
      backgroundColor: "#0E0C56",
      color: "white",
      cursor: "pointer",
    },
  },
}));

const AvatarBox = ({
  name,
  active,
  setCurrentEmployee,
  currentEmployee,
  empId,
}) => {
  const { classes } = useStyles();
  const { setCurrentSchedule } = useContext(AppContext);
  const handleClick = () => {
    if (active) {
      return;
    }
    setCurrentEmployee(empId);
    setCurrentSchedule(null);
  };
  return (
    <>
      <Grid.Col
        className={classes.avatarbox}
        style={{
          backgroundColor: active && "#0E0C56",
          color: active && "white",
        }}
        onClick={handleClick}
      >
        <Avatar color="cyan" radius="xl" mr="10px">
          {name?.slice(0, 2)}
        </Avatar>
        <Text size="lg" weight={600}>
          {name}
        </Text>
      </Grid.Col>
    </>
  );
};

export default AvatarBox;
