export const navLinks = [
  { link: "dashboard", label: "Dashboard", icon: "radix-icons:dashboard" },
  { link: "employee", label: "Employee", icon: "bi:people" },
  { link: "roster", label: "Roster", icon: "uil:calender" },
  { link: "timesheet", label: "TimeSheet", icon: "bx:time" },
  { link: "location", label: "Locations", icon: "ic:baseline-place" },
  { link: "message", label: "Messages", icon: "akar-icons:envelope" },
];

export const LOGINPAGE = "/";

export const URLFORMAT = "http://{userURL}/";
