import { Icon } from "@iconify/react";
import { Avatar, createStyles, Input } from "@mantine/core";
import React from "react";
import { useContext } from "react";
import { ImConnection } from "react-icons/im";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../context/AppContext";

const useStyles = createStyles(() => {
  return {
    container: {
      display: "flex",
      justifyContent: "space-between",
      paddingBottom: "30px",
      marginTop: "0.3rem",
    },
    title: {
      fontSize: "larger",
      fontWeight: "bold",
    },
    rightContainer: {
      display: "flex",
      marginLeft: "4rem",
      gap: "2.5rem",
    },
    userMail: {
      marginTop: ".6rem",
    },

    headerAvatar: {
      marginTop: "",
    },
    iconBell: {
      marginTop: " .4rem",
    },
  };
});
const PageHeader = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const title = location.pathname.split("/")[2].toUpperCase();
  const { user } = useContext(AppContext);
  return (
    <div className={classes.container}>
      <div className={classes.title}>{title}</div>
      <div className={classes.rightContainer}>
        <p className={classes.userMail}> {user?.email}</p>
      </div>
    </div>
  );
};

export default PageHeader;
