import {
    ActionIcon,
    Avatar,
    Badge,
    Button,
    Center,
    Drawer,
    Progress, ScrollArea,
    Space,
    Stack,
    Switch,
    Text,
} from "@mantine/core";
import dayjs from "dayjs";
import React, {useContext} from "react";
import {useState} from "react";
import {AiFillPhone, AiOutlineMail} from "react-icons/ai";
import {useQueryClient} from "react-query";
import {Edit} from "tabler-icons-react";
import {AppContext} from "../../context/AppContext";
import DocumentModal from "./DocumentModal";
import {useDisableUser} from "./services/useDisableUser";
import {useDeleteUser} from "./services/useDeleteUser";

const EmployeeDrawer = ({
                            handleDrawerClose,
                            opened,
                            selectedEmployee,
                            setRoleModal,
                        }) => {
    const {mutate} = useDisableUser();
    const {mutate: mutateDelete} = useDeleteUser();
    const {setEmployeeRosterModal} = useContext(AppContext);
    const [documentModal, setDocumentModal] = useState(false);
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData(["employees"]);
    let currentEmployee;
    if (selectedEmployee) {
        currentEmployee = data.find((e) => e.id === selectedEmployee);
    }
    console.log(currentEmployee);
    const handleViewRoster = () => {
        setEmployeeRosterModal({
            opened: true,
            id: selectedEmployee,
        });
    };

    const handleToggleUser = () => {
        mutate({id: selectedEmployee});
    };

    const handleDelete = () => {
        mutateDelete({id: selectedEmployee},{
            onSuccess: () => {
                handleDrawerClose();
            }
        });
    }
    return (
        <Drawer
            opened={opened}
            onClose={handleDrawerClose}
            padding="xs"
            position="right"
            size="22%"
        >
            <ScrollArea style={{height:"90vh"}} w={"100%"} type={"always"}>
            {currentEmployee && (
                <>
                    <Avatar size={100} mx="auto"/>
                    <Text size="lg" weight={600} align="center">
                        {currentEmployee?.employee?.name}
                    </Text>
                    <Text size="md" weight={500} align="center" my={"xs"}>
                        <Text component="span" color={"dimmed"}>
                            Joined
                        </Text>{" "}
                        : {dayjs(currentEmployee?.created_at).format("YYYY-MM-DD")}
                    </Text>
                    <Text
                        color="#696969"
                        weight={400}
                        align="center"
                        style={{display: "flex", justifyContent: "center", gap: "20px"}}
                    >
                        {currentEmployee?.role}
                        <ActionIcon onClick={() => setRoleModal(true)}>
                            <Edit/>
                        </ActionIcon>
                    </Text>
                    <Space h={10}/>
                    <Center>
                        <Text
                            color="#696969"
                            weight={400}
                            align="center"
                            style={{display: "flex", justifyContent: "center", gap: "20px"}}
                        >
                            {currentEmployee.employee.active ? "Active" : "Disabled"}
                            <Switch
                                onChange={handleToggleUser}
                                checked={currentEmployee.employee.active}
                            />
                        </Text>
                    </Center>
                    <Space h={10}/>
                    <div>
                        {currentEmployee?.employee?.active &&
                        currentEmployee?.employee?.Bank?.status &&
                        currentEmployee?.employee?.Document?.status &&
                        currentEmployee?.employee?.Tax?.status &&
                        currentEmployee?.employee?.quizStatus ? (
                            <Center>
                                <Badge>Verified</Badge>
                            </Center>
                        ) : (
                            <Progress
                                radius="xl"
                                size={24}
                                sections={[
                                    {
                                        value: 33,
                                        color: currentEmployee?.employee?.active ? "green" : "gray",
                                        label: "Details",
                                    },
                                    {
                                        value: 33,
                                        color: currentEmployee?.employee?.quizStatus
                                            ? "green"
                                            : "gray",
                                        label: "Induction",
                                    },
                                    {
                                        value: 33,
                                        color:
                                            currentEmployee?.employee?.Bank?.status &&
                                            currentEmployee?.employee?.Document?.status &&
                                            currentEmployee?.employee?.Tax?.status
                                                ? "green"
                                                : "gray",
                                        label: "Id",
                                    },
                                ]}
                            />
                        )}
                    </div>
                    <Space h={17}/>
                    <Text color="#696969" weight={400} size="sm" align="center">
                        EMAIL
                    </Text>
                    <Text size="md" weight={500} align="center">
                        {currentEmployee.email}
                    </Text>
                    <Space h={13}/>
                    <Text color="#696969" weight={400} align="center">
                        MOBILE
                    </Text>
                    <Text size="md" weight={500} align="center">
                        {currentEmployee.employee.phone}
                    </Text>
                    <Space h={13}/>
                    <Text color="#696969" weight={400} align="center">
                        SITE
                    </Text>

                        <Button
                            component="a"
                            radius="xl"
                            my={5}
                            fullWidth
                            onClick={handleViewRoster}
                            styles={(theme) => ({
                                root: {
                                    backgroundColor: "#0E0C56",
                                    width: "100%",
                                    borderRadius: "",
                                    "&:hover": {
                                        backgroundColor: "#0E0C56",
                                        opacity: "0.93",
                                    },
                                },
                            })}
                        >
                            View Roster
                        </Button>
                        <Button
                            component="a"
                            fullWidth
                            my={5}
                            href="/app/timesheet"
                            radius="xl"
                            styles={(theme) => ({
                                root: {
                                    backgroundColor: "#0E0C56",
                                    width: "100%",
                                    borderRadius: "",

                                    "&:hover": {
                                        backgroundColor: "#0E0C56",
                                        opacity: "0.93",
                                    },
                                },
                            })}
                        >
                            View Attendance
                        </Button>
                        <Button
                            component="a"
                            radius="xl"
                            fullWidth
                            my={5}
                            styles={(theme) => ({
                                root: {
                                    backgroundColor: "#0E0C56",
                                    width: "100%",
                                    borderRadius: "",

                                    "&:hover": {
                                        backgroundColor: "#0E0C56",
                                        opacity: "0.93",
                                    },
                                },
                            })}
                            onClick={() => setDocumentModal(true)}
                        >
                            View Documents
                        </Button>
                        <Button
                            component="a"
                            href="/app/message"
                            fullWidth
                            radius="xl"
                            my={5}
                            styles={(theme) => ({
                                root: {
                                    backgroundColor: "#0E0C56",
                                    width: "100%",
                                    borderRadius: "",

                                    "&:hover": {
                                        backgroundColor: "#0E0C56",
                                        opacity: "0.93",
                                    },
                                },
                            })}
                        >
                            Send Messages
                        </Button>
                        <Button
                            onClick={handleDelete}
                            fullWidth
                            my={5}
                            color={"red"}
                            radius="xl"
                        >
                            Delete
                        </Button>
                </>
            )}
            <DocumentModal
                selectedEmployee={selectedEmployee}
                documentModal={documentModal}
                setDocumentModal={setDocumentModal}
            />
                    </ScrollArea>
        </Drawer>
    );
};

export default EmployeeDrawer;
