import { createStyles, Text, UnstyledButton } from "@mantine/core";
import dayjs from "dayjs";
import React, { useContext } from "react";
import { ChevronLeft, ChevronRight } from "tabler-icons-react";
import { AppContext } from "../../context/AppContext";

const useStyles = createStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: "20px",
  },

  icon: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: theme.spacing.lg,
    color: "none",
  },

  stat: {
    minWidth: 98,
    paddingTop: theme.spacing.xl,
    minHeight: 130,
    display: "flex",
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: theme.white,
  },

  label: {
    textTransform: "uppercase",
    fontWeight: 700,
    fontSize: theme.fontSizes.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    color: theme.colors.gray[6],
    lineHeight: 1.2,
  },

  value: {
    fontSize: theme.fontSizes.sm,
    fontWeight: 700,
    color: theme.black,
  },

  count: {
    color: theme.colors.gray[6],
  },

  day: {
    fontSize: theme.md,
    fontWeight: 600,
    color: theme.black,
    // lineHeight: 1,
    textAlign: "center",
    marginRight: 3,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
  },

  month: {
    fontSize: theme.fontSizes.md,
    color: theme.black,
    // lineHeight: 1,
    textAlign: "center",
  },

  controls: {
    display: "flex",
    justifyContent: "space-around",
  },

  date: {
    display: "flex",
    width: "400px",
  },

  control: {
    height: 28,
    width: "100%",
    color: theme.colors[theme.primaryColor][2],
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: theme.radius.md,
    transition: "background-color 50ms ease",

    [theme.fn.smallerThan("xs")]: {
      height: 34,
      width: 34,
    },

    "&:hover": {
      backgroundColor: "#E9E9ED",
      color: theme.black,
    },
  },
}));

const DateSelector = (props) => {
  const { classes } = useStyles();
  const { currentSchedule, setCurrentSchedule } = useContext(AppContext);

  const handlePrev = () => {
    setCurrentSchedule(null);
    props.setDate((current) => {
      return {
        end: dayjs(current.start).subtract(1, "day").toDate(),
        start: dayjs(current.end).subtract(13, "day").toDate(),
      };
    });
  };
  const handleNext = () => {
    setCurrentSchedule(null);
    props.setDate((current) => {
      return {
        start: dayjs(current.end).add(1, "day").toDate(),
        end: dayjs(current.start).add(13, "day").toDate(),
      };
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.controls}>
        <UnstyledButton className={classes.control} onClick={handlePrev}>
          <ChevronLeft className={classes.controlIcon} />
        </UnstyledButton>

        <div className={classes.date}>
          <Text className={classes.day}>
            {dayjs(props.date.start).format("DD")}
          </Text>
          <Text className={classes.month}>
            {dayjs(props.date.start).format("MMM")}
          </Text>
          <Text size="lg" style={{ padding: "0 .4em" }}>
            {" "}
            -{" "}
          </Text>
          <Text className={classes.day}>
            {dayjs(props.date.end).format("DD")}
          </Text>
          <Text className={classes.month}>
            {dayjs(props.date.end).format("MMM")}
          </Text>
        </div>
        <UnstyledButton className={classes.control} onClick={handleNext}>
          <ChevronRight className={classes.controlIcon} />
        </UnstyledButton>
      </div>
      {/* <Group sx={{ flex: 1 }}>{stats}</Group> */}
    </div>
  );
};

export default DateSelector;
