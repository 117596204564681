import { showNotification } from "@mantine/notifications";
import { useMutation, useQueryClient } from "react-query";
import { SCHEDULE_ROUTE } from "../../../utils/ApiRoutes";
import { UpdateQuery } from "../../../utils/QueryCalls";


const ApproveAllTimesheet = async ({ query, id }) => {
    return await UpdateQuery(SCHEDULE_ROUTE + "/approveall/" + id + "/" + query)
}

export const useApproveAllTimesheet = () => {
    const queryClient = useQueryClient()

    return useMutation(ApproveAllTimesheet, {
        onSuccess: () => {
            queryClient.invalidateQueries(['Timesheets'])
            showNotification({
                title: "Approved",
                color: "green"
            })
        },
        onError: (error) => {
            showNotification({
                title: "Could not approve schedule",
                message: error.response.data.message,
                color: "red"
            })
        }
    });
}