import { Icon } from "@iconify/react";
import {
  AppShell,
  Burger,
  createStyles,
  MediaQuery,
  Navbar,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import axios from "axios";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Logout } from "tabler-icons-react";
import { PostData } from "../../utils/ApiCalls";
import { LOGOUT_ROUTE } from "../../utils/ApiRoutes";
import { LOGINPAGE, navLinks } from "../../utils/Constants";
import PageHeader from "./PageHeader";

const useStyles = createStyles((theme, _params, getRef) => {
  const icon = getRef("icon");

  return {
    navbar: {
      backgroundColor: "#0E0C56",
      overflow: "hidden",
    },

    header: {
      paddingBottom: theme.spacing.sm,
      marginBottom: theme.spacing.sm,
      color: "white",
      textAlign: "center",
      fontSize: "30px",
    },
    footer: {
      paddingTop: theme.spacing.sm,
      marginTop: theme.spacing.sm,
    },

    link: {
      ...theme.fn.focusStyles(),
      display: "flex",
      alignItems: "center",
      fontSize: "20px",
      textDecoration: "none",
      padding: `${theme.spacing.md}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      fontWeight: 400,
      color: "white",
      position: "relative",
      "&:hover": {
        color: "#FCBA59",
        [`& .${icon}`]: {
          opacity: 0.9,
          color: "#FCBA59",
        },
      },
    },

    linkIcon: {
      ref: icon,
      color: "#white",
      marginRight: theme.spacing.sm,
      fontSize: "22px",
    },

    labelName: {
      fontSize: "18px",
      paddingLeft: "20px",
    },
    urlLink: {
      display: "flex",
      justifyContent: "space-between",
      cursor: "pointer",
    },
    linkActive: {
      color: "#FCBA59",
      "&:before": {
        content: '""',
        borderRadius: "10px",
        backgroundColor: "#FCBA59",
        height: "60%",
        left: "0",
        width: "4px",
        zIndex: "999",
        position: "absolute",
      },
    },
  };
});

const Dashboard = ({ children }) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const location = useLocation();

  const handleLogout = async () => {
    await PostData(LOGOUT_ROUTE, {});
    window.location.pathname = LOGINPAGE;
  };
  const links = navLinks.map((link) => (
    <Link
      to={`/app/${link.link}`}
      className={cx(classes.link, {
        [classes.linkActive]: location.pathname.includes(link.link),
      })}
      key={link.label}
    >
      <Icon icon={link.icon} className={classes.linkIcon} />
      <span className={classes.labelName}>{link.label}</span>
    </Link>
  ));

  return (
    <>
      <AppShell
        styles={{
          main: {
            background:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
          borderRadius: "20px",
        }}
        navbarOffsetBreakpoint="sm"
        asideOffsetBreakpoint="sm"
        fixed
        navbar={
          <Navbar
            className={classes.navbar}
            p="md"
            hiddenBreakpoint="sm"
            hidden={!opened}
            width={{ sm: 200, lg: 220 }}
          >
            <Navbar.Section className={classes.header}>
              <img
                src="/logo.png"
                alt="Logo"
                width={100}
                style={{ filter: "brightness(0) invert(1)" }}
              />
            </Navbar.Section>
            <Navbar.Section grow>{links}</Navbar.Section>
            {/* Link button */}
            <Navbar.Section className={classes.footer}>
              <UnstyledButton onClick={handleLogout} className={classes.link}>
                <Logout className={classes.linkIcon} />
                <span className={classes.labelName}>Logout</span>
              </UnstyledButton>
            </Navbar.Section>
          </Navbar>
        }
        header={
          <div
            style={{ display: "flex", alignItems: "center", height: "100%" }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
          </div>
        }
      >
        <PageHeader />
        <div>{children}</div>
      </AppShell>
    </>
  );
};

export default Dashboard;
