import { createStyles, Grid, Text } from "@mantine/core";
import { useId } from "@mantine/hooks";
import React from "react";
import * as dayjs from "dayjs";
import * as utc from "dayjs/plugin/utc";
const useStyles = createStyles((theme, _params, getRef) => ({
  cellbox: {
    backgroundColor: "#5e53e8",
    color: "white",
    padding: "10px 10px",
    borderRadius: "30px",
    textAlign: "center",
    marginBottom: "3vh",
    width: "140px",
    ":hover": { backgroundColor: "#330785", color: "white" },
  },
  p: {
    fontSize: "14px",
    fontWeight: "bold",
  },
  span: {
    fontSize: "10px",
  },
  workSheet: {
    fontWeight: "500",
    alignSelf: "center",
    textAlign: "center",
    fontSize: "large",
  },
  selectedCol: {
    borderLeft: "1px solid lightgray",
    borderBottom: "1px solid lightgray",
    "&:hover": {
      button: {
        display: "block",
      },
    },
    width: "100%",
  },
  editBtn: {
    display: "none",
  },
}));

const CellBox = ({ schedule, date, id }) => {
  const { classes } = useStyles();
  const uuid = useId(id);
  dayjs.extend(utc);
  // console.log(schedule);
  return (
    <Grid.Col span={2} className={classes.selectedCol}>
      {schedule ? (
        schedule?.map((single, i) => (
          <Text
            size="lg"
            style={{
              backgroundColor: "#1864ab",
              color: "white",
              borderRadius: "30px",
              padding: "0 5px",
              textAlign: "center",
            }}
          >
            {dayjs(single.start_at).utc(false).format("HH:mm")}-{" "}
            {dayjs(single.end_at).utc(false).format("HH:mm")}
          </Text>
        ))
      ) : (
        <p key={uuid + Math.random().toString()}>No shifts</p>
      )}
    </Grid.Col>
  );
};

export default CellBox;
